/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { AppSyncFarm, AppSyncFarmToken, MonitorVaults } from 'common/models/monitorVaults';
import { StyledFlexRow, StyledTableBodyTr, StyledTd, StyledTdItem } from 'common/styles/common.styles';
import React from 'react';
import FormatNumber from '../FormatPercent/FormatNumber';
import { StyledMonitorVaultsCoinName, StyledMonitorVaultsSubTextOne } from './monitorVaultsTable.style';
import MonitorVaultsTablePositionDetails from './MonitorVaultsTablePositionDetails';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import { FarmUtils } from 'utils/farmUtils';
import { RebalancingStrategies } from 'common/models/strategies';

type VaultPositions = {
  baseLower: number;
  baseUpper: number;
  limitLower: number;
  limitUpper: number;
  currentTick: number;
};

function isAtMaxOrMinTick(edge: number): boolean{
  return edge > 800000 || edge < -800000;
}
function isSpreadOut(lower: number, upper: number): boolean {
  return isAtMaxOrMinTick(lower) || isAtMaxOrMinTick(upper);
}
function isDefensive(positions: VaultPositions): boolean {
  const baseL = positions.baseLower;
  const baseU = positions.baseUpper;
  const limitL = positions.limitLower;
  const limitU = positions.limitUpper;
  return (isSpreadOut(baseL, baseU) && isSpreadOut(limitL, limitU)) 
    || (!isSpreadOut(baseL, baseU) && !isSpreadOut(limitL, limitU));
}
function isHealthy(positions: VaultPositions): boolean {
  const baseL = positions.baseLower;
  const baseU = positions.baseUpper;
  const limitL = positions.limitLower;
  const limitU = positions.limitUpper;
  return !isSpreadOut(baseL, baseU) && isSpreadOut(limitL, limitU); 
}

function isTickInsideExtendedBasePos(positions: VaultPositions): boolean {
  const baseL = positions.baseLower;
  const baseU = positions.baseUpper;
  const tick = positions.currentTick;
  return baseL - 100 <= tick && tick <= baseU + 100;
}

function isUnderInventory(positions: VaultPositions, isInverted: boolean): boolean {
  const baseL = positions.baseLower;
  const baseU = positions.baseUpper;
  const limitL = positions.limitLower;
  const limitU = positions.limitUpper;
  if (isHealthy(positions) || isDefensive(positions)) { return false; }
  const isLeftPositionStreadOut = 
    (baseL <= limitL && baseL <= limitU && baseU <= limitL && baseU <= limitU && isSpreadOut(baseL, baseU))
    || (limitL <= baseL && limitL <= baseU && limitU <= baseL && limitU <= baseU && isSpreadOut(limitL, limitU)); 
  return (!isLeftPositionStreadOut && !isInverted)
    || (isLeftPositionStreadOut && isInverted); 
}

function isOverInventory(positions: VaultPositions, isInverted: boolean): boolean {
  const baseL = positions.baseLower;
  const baseU = positions.baseUpper;
  const limitL = positions.limitLower;
  const limitU = positions.limitUpper;
  if (isHealthy(positions) || isDefensive(positions)) { return false; }
  const isLeftPositionStreadOut = 
    (baseL <= limitL && baseL <= limitU && baseU <= limitL && baseU <= limitU && isSpreadOut(baseL, baseU))
    || (limitL <= baseL && limitL <= baseU && limitU <= baseL && limitU <= baseU && isSpreadOut(limitL, limitU)); 
  return (isLeftPositionStreadOut && !isInverted)
    || (!isLeftPositionStreadOut && isInverted); 
}

function getPairedTokenRatio(vault: MonitorVaults, farms: AppSyncFarm[], vaults: MonitorVaults[]): number{
  const farm = farms.filter((f) => f.lpAddress.toLowerCase() === vault.address.toLowerCase())[0];
  const isInverted = farm.tokens[1].isBaseToken;
  const token = isInverted ? farm.tokens[1] : farm.tokens[0];
  const tokenPrice = token.price;
  const pairedToken = isInverted ? farm.tokens[0] : farm.tokens[1];
  const pairedTokenPrice = pairedToken.price;
  const twinFarms = farms.filter((f) => 
    f.tokens[0].address.toLowerCase() === farm.tokens[0].address.toLowerCase()
    && f.tokens[1].address.toLowerCase() === farm.tokens[1].address.toLowerCase()
    && f.tokens[0].isBaseToken === farm.tokens[1].isBaseToken);
  let twinFarm: AppSyncFarm | null = null;
  if (twinFarms.length > 0) {
    twinFarm = twinFarms[0];
  } else {
    // if there's no paired vault, returns -1;
    return -1;
  }
  const twinVault = vaults.filter((v) => v.address.toLowerCase() === twinFarm?.lpAddress.toLowerCase())[0];
  const tokenValue = vault.baseTokenValue * tokenPrice + twinVault?.scarceTokenValue * pairedTokenPrice;
  const pairedTokenValue = vault.scarceTokenValue * tokenPrice + twinVault?.baseTokenValue * pairedTokenPrice;
  const ratio = tokenValue / (tokenValue + pairedTokenValue) * 100;

  return ratio;
}

type Props = {
  vault: MonitorVaults;
  token: Nullable<AppSyncFarmToken>;
  chainId: ChainId;
  vaultTokenDecimals: [number, number];
  depositTokenPriceUsdc: number;
  lpPrice: number;
  strategy: RebalancingStrategies | undefined;
  farms: AppSyncFarm[];
  vaults: MonitorVaults[];
};

const MonitorVaultsTablePosition: React.FC<Props> = (props) => {
  const [isVaultSelected, setIsVaultSelected] = React.useState(false);
  const toggleSelected = () => {
    setIsVaultSelected(!isVaultSelected);
  };

  const vault = props.vault;
  const needRebalanceFor =
    (Math.abs(new Date().getTime() - Number(vault.needRebalanceFrom)) / (24 * 60 * 60 * 1000)).toFixed(0);
  let alertClassName = '';
  if (Number(needRebalanceFor) > 12) {
    alertClassName = 'warning-text-color bold';
  }
  if (Number(needRebalanceFor) > 24) {
    alertClassName = 'alert-text-color bold';
  }

  // console.log(`${vault.name} last rebalance: ${vault.lastRebalance}`);
  const lastRebalance = (new Date(Number(vault.lastRebalance) * 1000))
    .toLocaleDateString('en-US',
      { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });

  const vaultName = (vault.displayName ?? vault.name).split('(')[0];


  const priceInDepositTokens: number = FarmUtils.getPriceInDepositToken(vault.isInverted, props.vaultTokenDecimals, vault.positions?.currentTick);
  const priceInUsdc: number = priceInDepositTokens * props.depositTokenPriceUsdc;
  let baseLowerInDepositTokens = FarmUtils.getPriceInDepositToken(vault.isInverted, props.vaultTokenDecimals, vault.positions?.baseLower);
  let baseUpperInDepositTokens = FarmUtils.getPriceInDepositToken(vault.isInverted, props.vaultTokenDecimals, vault.positions?.baseUpper);
  let limitLowerInDepositTokens = FarmUtils.getPriceInDepositToken(vault.isInverted, props.vaultTokenDecimals, vault.positions?.limitLower);
  let limitUpperInDepositTokens = FarmUtils.getPriceInDepositToken(vault.isInverted, props.vaultTokenDecimals, vault.positions?.limitUpper);
  if (vault.isInverted) {
    baseLowerInDepositTokens = vault.positions?.baseLower < -800000 ? 0 : baseLowerInDepositTokens;
    baseUpperInDepositTokens = vault.positions?.baseUpper > 800000 ? Infinity : baseUpperInDepositTokens;
    limitLowerInDepositTokens = vault.positions?.limitLower < -800000 ? 0 : limitLowerInDepositTokens;
    limitUpperInDepositTokens = vault.positions?.limitUpper > 800000 ? Infinity : limitUpperInDepositTokens;
  } else {
    baseLowerInDepositTokens = vault.positions?.baseLower < -800000 ? Infinity : baseLowerInDepositTokens;
    baseUpperInDepositTokens = vault.positions?.baseUpper > 800000 ? 0 : baseUpperInDepositTokens;
    limitLowerInDepositTokens = vault.positions?.limitLower < -800000 ? Infinity : limitLowerInDepositTokens;
    limitUpperInDepositTokens = vault.positions?.limitUpper > 800000 ? 0 : limitUpperInDepositTokens;
  }

  const lpPrice = props.lpPrice;

  const hasRegularStrategy = props.strategy && props.strategy.strategyType && props.strategy.strategyType.includes('regular');

  let vaultState;
  if (hasRegularStrategy && isHealthy(vault.positions) && isTickInsideExtendedBasePos(vault.positions)){
    vaultState = (<strong className="primary-color">H</strong>);
  }
  if (hasRegularStrategy && isHealthy(vault.positions) && !isTickInsideExtendedBasePos(vault.positions)){
    vaultState = (<strong className="alert-state">H</strong>);
  }
  if (hasRegularStrategy && isDefensive(vault.positions)){
    vaultState = (<strong>D</strong>);
  }
  if (hasRegularStrategy && isUnderInventory(vault.positions, vault.isInverted)){
    vaultState = (<strong className="alert-text-color">U</strong>);
  }
  if (hasRegularStrategy && isOverInventory(vault.positions, vault.isInverted)){
    vaultState = (<strong className="warning-text-color">O</strong>);
  }

  // show vault name in red if deposits are locked or strategy is paused
  const depositsNotAllowed = vault.maxDeposit === 0;
  const isPaused = props.strategy?.paused;
  const vaultNameClass = depositsNotAllowed || isPaused ? 'alert-text-color' : '';

  const isCELO = props.chainId === ChainId.Celo;
  const ratio = isCELO ? getPairedTokenRatio(vault, props.farms, props.vaults) : -1;

  return (
    <>
      <StyledTableBodyTr
        className={isVaultSelected ? 'no-border-bottom cursor-pointer' : 'table-border-bottom cursor-pointer'}
        key={vault.name}
        onClick={toggleSelected}
      >
        {/* ---------------------- */}
        {/* Name */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color">
            <StyledMonitorVaultsCoinName className="primary-text-color">
              <span className={vaultNameClass}>{vaultName}</span>
              {depositsNotAllowed && (<span className={vaultNameClass}>locked</span>)}
              {isPaused && (<span className={vaultNameClass}>paused</span>)}
            </StyledMonitorVaultsCoinName>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Deposit Token Ratio */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3 text-small">
            <FormatNumber value={100 - props.vault.memberTokenRatio} displayDecimals={1} postfix="%" />
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Current Price */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3 text-small">
            <StyledFlexRow gap='4px'>
              <FormatNumber value={priceInDepositTokens} displayDecimals={priceInDepositTokens < 0.001 ? 5 : 2 }  />
              <FormatNumber value={priceInUsdc} displayDecimals={priceInUsdc < 0.001 ? 5 : 2 } prefix='($' postfix=')' />
            </StyledFlexRow>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Vault State */}
        {/* ---------------------- */}
        <StyledTd className='px-1 primary-text-color pb-3 text-small'>
          {vaultState}
        </StyledTd>
        {/* ---------------------- */}
        {/* Base Range */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3 text-small">
            <StyledFlexRow gap='2px'>
              <FormatNumber 
                value={baseLowerInDepositTokens}
                displayDecimals={baseLowerInDepositTokens < 0.001 ? 10 : 2 } /> - 
              <FormatNumber 
                value={baseUpperInDepositTokens}
                displayDecimals={baseUpperInDepositTokens < 0.001 ? 10 : 2 } />
            </StyledFlexRow>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Limit Range */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3 text-small">
            <StyledFlexRow gap='2px'>
              <FormatNumber
                value={limitLowerInDepositTokens}
                displayDecimals={limitLowerInDepositTokens < 0.001 ? 10 : 2 } /> -
              <FormatNumber
                value={limitUpperInDepositTokens}
                displayDecimals={limitUpperInDepositTokens < 0.001 ? 10 : 2 } />
            </StyledFlexRow>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Pending Deposit Ratio */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3 text-small">
            <FormatNumber value={props.vault.pendingDepositsRatio} displayDecimals={1} postfix="%" />
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Last Rebalance */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            {props.vault.lastRebalance !== '0' && (
              <StyledMonitorVaultsSubTextOne className="primary-text-color">
                {lastRebalance}
              </StyledMonitorVaultsSubTextOne>
            )}
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* LP price or Comb. Ratio for CELO */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3 text-small">
            {isCELO && ratio !== -1 
              ? (<StyledFlexRow gap='2px'>
                <FormatNumber value={ratio} displayDecimals={0} postfix="% " abbreviate={true} /> / 
                <FormatNumber value={100 - ratio} displayDecimals={0} postfix="%" prefix=" " abbreviate={true} />
              </StyledFlexRow>)
              : ( isCELO 
                ? '-' 
                : (<FormatNumber value={lpPrice} displayDecimals={2} prefix="$" abbreviate={true} />))
            }
          </StyledTdItem>
        </StyledTd>
      </StyledTableBodyTr>
      <MonitorVaultsTablePositionDetails
        isShown={isVaultSelected}
        vault={props.vault}
        farms={props.farms}
        vaults={props.vaults}
        token={props.token}
        chainId={props.chainId}
        lpPrice={props.lpPrice}
        strategy={props.strategy}
      />
    </>
  );
};

export default MonitorVaultsTablePosition;
