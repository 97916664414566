/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import FormatFloat from 'common/components/FormatPercent/FormatFloat';
import { AppSyncFarm, AppSyncFarmToken, MonitorVaults } from 'common/models/monitorVaults';
import { StyledTableBodyTr, StyledTdSub, StyledTdItem, StyledFlexRow } from 'common/styles/common.styles';
import React from 'react';
import FormatNumber from '../FormatPercent/FormatNumber';
import SupportIrrList from './SupportIrrList';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import PairedVaultsBalance from './PairedVaultsBalance';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import { RebalancingStrategies } from 'common/models/strategies';

type Props = {
  vault: MonitorVaults;
  isShown: boolean;
  token: Nullable<AppSyncFarmToken>;
  chainId: ChainId;
  farms: AppSyncFarm[];
  vaults: MonitorVaults[];
  lpPrice: number;
  strategy: RebalancingStrategies | undefined;
};

const MonitorVaultsTablePositionDetails: React.FC<Props> = (props) => {
  const isHodlVault = props.vault.isHodlVault;
  const isInverted = props.vault.isInverted;
  const positions = props.vault.positions;

  const baseTokenName = props.token?.displayName;
  const amountPrefix = isHodlVault ? '' : '$';
  const amountPostfix = isHodlVault ? ` ${baseTokenName}` : '';
  const baseTokenPrice = props.token?.price ?? 0;

  const farm = props.farms.filter((f) => f.lpAddress.toLowerCase() === props.vault.address.toLowerCase())[0];
  const pairedToken = farm?.tokens[isInverted ? 0 : 1];

  let displayDecimals = 0;
  switch (true) {
    case baseTokenPrice > 10000:
      displayDecimals = 4;
      break;
    case baseTokenPrice > 1000:
      displayDecimals = 3;
      break;
    case baseTokenPrice > 100:
      displayDecimals = 2;
      break;
    case baseTokenPrice > 10:
      displayDecimals = 1;
      break;
    default:
      displayDecimals = 0;
      break;
  }

  const tvlUSD = props.vault.tvl * baseTokenPrice;

  const isBaseAndLimitSwitched = 
    ((isInverted && (positions.baseUpper > positions.limitLower)) ||
    (!isInverted && (positions.baseLower < positions.limitUpper)));

  let irrFiltered = props.vault.vaultIRR;
  let irrAllTx = props.vault.vaultIrrAllTx;
  let irrNote = '';

  // irr and filtered irr are switch for these vaults:
  // if (vault.vaultName === 'oneichi_ichi' || 
  // vault.vaultName === 'usdc-ichi' || 
  // vault.vaultName === 'pol_usdc_weth' || 
  // vault.vaultName === 'pol_usdc'){
  // switch them back
  // change this if changed in apis/monitor-cron
  if (props.vault.name === 'oneichi_ichi' || 
    props.vault.name === 'usdc-ichi' || 
    props.vault.name === 'pol_usdc_weth' || 
    props.vault.name === 'pol_usdc'){
    [irrFiltered, irrAllTx] = [irrAllTx, irrFiltered];
    irrNote = 'UI value';
  }

  const avgDTR = (props.vault.vaultMetrics && props.vault.vaultMetrics[0]) 
    ? props.vault.vaultMetrics[0].avgDTR.toFixed(2)+'%' : '';
  const lpPriceChange = (props.vault.vaultMetrics && props.vault.vaultMetrics[0]) 
    ? Number(props.vault.vaultMetrics[0].lpPriceChange).toFixed(2)+'%' : '';
  const lpApr = (props.vault.vaultMetrics && props.vault.vaultMetrics[0]) 
    ? Number(props.vault.vaultMetrics[0].lpApr).toFixed(2)+'%' : '';
  const feeApr = (props.vault.vaultMetrics && props.vault.vaultMetrics[0]) 
    ? props.vault.vaultMetrics[0].feeApr.toFixed(2)+'%' : '';

  const avgDTR1 = (props.vault.vaultMetrics && props.vault.vaultMetrics[1]) 
    ? props.vault.vaultMetrics[1].avgDTR.toFixed(2)+'%' : '';
  const lpPriceChange1 = (props.vault.vaultMetrics && props.vault.vaultMetrics[1]) 
    ? Number(props.vault.vaultMetrics[1].lpPriceChange).toFixed(2)+'%' : '';
  const lpApr1 = (props.vault.vaultMetrics && props.vault.vaultMetrics[1]) 
    ? Number(props.vault.vaultMetrics[1].lpApr).toFixed(2)+'%' : '';
  const feeApr1 = (props.vault.vaultMetrics && props.vault.vaultMetrics[1]) 
    ? props.vault.vaultMetrics[1].feeApr.toFixed(2)+'%' : '';

  if (props.isShown) {
    return (
      <>
        {/* ---------------------- */}
        {/* ROW 1: pendingDeposits & baseLower */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}1`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            pendingDeposits
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <FormatNumber 
              value={props.vault.pendingDeposits} 
              postfix={amountPostfix} 
              prefix={amountPrefix} 
              displayDecimals={displayDecimals}
            />
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            baseLower
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            {props.vault.positions?.baseLower}
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            LP price
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <FormatNumber value={props.lpPrice} displayDecimals={2} prefix="$" abbreviate={true} />
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 2: oneTokenValue & baseUpper */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}2`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            {isHodlVault ? (
              'baseTokenValue'
            ) : (
              'oneTokenValue'
            )}
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <FormatNumber 
              value={props.vault.baseTokenValue} 
              postfix={amountPostfix} 
              prefix={amountPrefix}
              displayDecimals={displayDecimals}
            />
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            baseUpper
          </StyledTdSub>
          <StyledTdSub colSpan={4}>
            {props.vault.positions?.baseUpper}
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 3: scarceTokenValue & limitLower */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}3`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            scarceTokenValue
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <FormatNumber 
              value={props.vault.scarceTokenValue} 
              postfix={amountPostfix} 
              prefix={amountPrefix} 
              displayDecimals={displayDecimals}
            />
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            <StyledTdItem className="primary-text-color pb-3">
              limitLower
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={4}>
            {props.vault.positions?.limitLower}
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 4: tvl & limitUpper */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}4`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            tvl                 
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <FormatNumber 
                value={props.vault.tvl} 
                postfix={`${amountPostfix}  `} 
                prefix={amountPrefix} 
                displayDecimals={displayDecimals}
              /> (
              <FormatFloat 
                value={tvlUSD} 
                prefix='$' 
                displayDecimals={0}
              /> )
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            <StyledTdItem className="primary-text-color pb-3">
              limitUpper
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={4}>
            {props.vault.positions?.limitUpper}
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 5: scarceTokenMarketCap & currentTick */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}5`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            scarceTokenMarketCap
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <FormatFloat value={props.vault.scarceTokenMarketCap} prefix="$" />
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            currentTick
          </StyledTdSub>
          <StyledTdSub colSpan={4}>
           {props.vault.positions?.currentTick}
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 6: sevenDaysChange */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}6`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            sevenDaysChange
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            {props.vault.sevenDaysChange}%
          </StyledTdSub>
          <StyledTdSub colSpan={4}>
            address: {props.vault.address}
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            <strong>
              <OutboundLink 
                href={`https://vaultmetrics.io/?vault_address=${props.vault.address}&chain_id=${props.chainId}`}
                target='blank'>
                Open on DASHBOARD 
              </OutboundLink>
            </strong>
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 7: vaultIRR & suggested rebalance ticks */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}7`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            IRR with filtered transactions
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledFlexRow gap='6px'>
              <FormatNumber value={irrFiltered} postfix="%" displayDecimals={2} />
              {irrNote}
            </StyledFlexRow>
          </StyledTdSub>
          <StyledTdSub colSpan={6}>
            strategy: {props.strategy?.strategy}
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 8: IRR for the last 30 days */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.vault.name}8`} className="table-no-border-bottom">
          <StyledTdSub colSpan={2}>
            IRR with all transactions
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledFlexRow gap='6px'>
              <FormatNumber value={irrAllTx} postfix="%" displayDecimals={2} />
              {irrNote === '' && (
                <>UI value</>
              )}
            </StyledFlexRow>
          </StyledTdSub>
          <StyledTdSub colSpan={2}>
            is Inverted: {isInverted ? 'yes' : 'no'}
          </StyledTdSub>
          <StyledTdSub colSpan={4}>
            is Base And Limit Switched: {isBaseAndLimitSwitched ? 'yes' : 'no'}
          </StyledTdSub>
        </StyledTableBodyTr>
        <PairedVaultsBalance 
          vault={props.vault}
          farms={props.farms}
          vaults={props.vaults}
        />
        <StyledTableBodyTr key={`${props.vault.name}10`} className="table-no-border-bottom">
          <StyledTdSub colSpan={9}>
            <table style={{marginBottom: 0}}>
              <tbody>
                <tr className="table-no-border-bottom">
                  <StyledTdSub colSpan={5}>
                    7 days
                  </StyledTdSub>
                  <StyledTdSub colSpan={5}>
                    30 days
                  </StyledTdSub>
                </tr>
                <tr>
                  <StyledTdSub colSpan={5}>
                    <div className="sub-text-color" style={{fontSize: '12px', lineHeight: '1.3em'}}>
                      <b>Average DTR:</b> {avgDTR}<br />
                      <b>LP Price Change:</b> {lpPriceChange}<br />
                      <b>LP APR:</b> {lpApr}<br />
                      <b>Fee APR:</b> {feeApr}<br />
                    </div>
                  </StyledTdSub>
                  <StyledTdSub colSpan={5}>
                    <div className="sub-text-color" style={{fontSize: '12px', lineHeight: '1.3em'}}>
                      <b>Average DTR:</b> {avgDTR1}<br />
                      <b>LP Price Change:</b> {lpPriceChange1}<br />
                      <b>LP APR:</b> {lpApr1}<br />
                      <b>Fee APR:</b> {feeApr1}<br />
                    </div>
                  </StyledTdSub>
                </tr>
              </tbody>
            </table>
          </StyledTdSub>
        </StyledTableBodyTr>
        <StyledTableBodyTr key={`${props.vault.name}9`} className="table-no-border-bottom">
          <td colSpan={9}>
            <SupportIrrList
              isShown={props.isShown}
              vault={props.vault}
              token={props.token}
            />
          </td>
        </StyledTableBodyTr>
        <StyledTableBodyTr key={`${props.vault.name}11`} className="table-no-border-bottom"><td colSpan={9} /></StyledTableBodyTr>
      </>
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (<></>);
  }
};

export default MonitorVaultsTablePositionDetails;
